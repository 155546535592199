import React, { useRef, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { createWaveformData } from '../utils/audioUtils';

const WaveformDisplay = ({ audioBlob, width, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const drawWaveform = async () => {
      if (!audioBlob || !canvasRef.current) return;

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // Set canvas dimensions
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const waveformData = await createWaveformData(audioBlob, canvas.width);

      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2);
      waveformData.forEach((point, index) => {
        const x = index;
        const y = (1 - point) * canvas.height;
        ctx.lineTo(x, y);
      });

      ctx.strokeStyle = '#3182CE';
      ctx.stroke();
    };

    drawWaveform();
  }, [audioBlob]);

  if (!audioBlob) return null;

  return (
    <Box position="absolute" top="0" left="0" width="100%" height="100%">
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};

export default WaveformDisplay;