import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button } from '@chakra-ui/react';

const AudioPlayer = ({ audioBlob, selection, onPlaybackStop }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const startTimeRef = useRef(0);
  const audioBufferRef = useRef(null);

  const stopAudio = useCallback(() => {
    if (sourceNodeRef.current) {
      sourceNodeRef.current.stop();
      sourceNodeRef.current.disconnect();
      sourceNodeRef.current = null;
    }
    setIsPlaying(false);
    onPlaybackStop();
  }, [onPlaybackStop]);

  useEffect(() => {
    if (audioBlob) {
      const loadAudio = async () => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const arrayBuffer = await audioBlob.arrayBuffer();
        audioBufferRef.current = await audioContextRef.current.decodeAudioData(arrayBuffer);
      };
      loadAudio();
    }
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [audioBlob]);

  useEffect(() => {
    stopAudio();
  }, [selection, stopAudio]);

  const playAudio = (start, duration) => {
    if (!audioBufferRef.current) return;

    stopAudio();

    sourceNodeRef.current = audioContextRef.current.createBufferSource();
    sourceNodeRef.current.buffer = audioBufferRef.current;
    sourceNodeRef.current.connect(audioContextRef.current.destination);
    
    sourceNodeRef.current.start(0, start, duration);
    startTimeRef.current = audioContextRef.current.currentTime - start;
    
    sourceNodeRef.current.onended = () => {
      setIsPlaying(false);
      onPlaybackStop();
    };

    setIsPlaying(true);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      stopAudio();
    } else {
      if (selection) {
        playAudio(selection.start, selection.end - selection.start);
      } else {
        playAudio(0);
      }
    }
  };

  return (
    <Button onClick={togglePlayPause} isDisabled={!audioBlob}>
      {isPlaying ? 'Pause' : 'Play'}
    </Button>
  );
};

export default AudioPlayer;