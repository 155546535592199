import React, { useState, useCallback, useEffect } from 'react';
import { ChakraProvider, Box, Button, VStack } from '@chakra-ui/react';
import AudioRecorder from './components/AudioRecorder';
import AudioTimeline from './components/AudioTimeline';
import AudioPlayer from './components/AudioPlayer';
import ExportModal from './components/ExportModal';

function App() {
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [selection, setSelection] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleRecordingComplete = useCallback((blob) => {
    setAudioBlob(blob);
  }, []);

  useEffect(() => {
    if (audioBlob) {
      const getAudioDuration = async () => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        setAudioDuration(audioBuffer.duration);
      };

      getAudioDuration();
    }
  }, [audioBlob]);

  const handleTimelineClick = () => {
    setSelection(prev => ({...prev}));
  };

  const handlePlaybackStop = () => {
    // You can add any additional logic here if needed
  };

  const openExportModal = () => {
    setShowModal(true);
  };

  const closeExportModal = () => {
    setShowModal(false);
  };

  return (
    <ChakraProvider>
      <Box p={4}>
        <VStack spacing={4} align="stretch">
          <AudioRecorder onRecordingComplete={handleRecordingComplete} />
          {audioBlob && audioDuration > 0 && (
            <>
              <AudioTimeline
                duration={audioDuration}
                onSelectionChange={setSelection}
                audioBlob={audioBlob}
                onTimelineClick={handleTimelineClick}
              />
              <AudioPlayer
                audioBlob={audioBlob}
                selection={selection}
                onPlaybackStop={handlePlaybackStop}
              />
              <Button onClick={openExportModal} colorScheme="blue">
                Export Audio
              </Button>
            </>
          )}
        </VStack>
        <ExportModal
          isOpen={showModal}
          onClose={closeExportModal}
          audioBlob={audioBlob}
          selection={selection}
        />
      </Box>
    </ChakraProvider>
  );
}

export default App;