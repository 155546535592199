import React from 'react';
import { Button, Box } from '@chakra-ui/react';
import useAudioRecorder from '../hooks/useAudioRecorder';
import WaveformDisplay from './WaveformDisplay';

const AudioRecorder = ({ onRecordingComplete }) => {
  const { isRecording, startRecording, stopRecording, audioBlob, getAudioData } = useAudioRecorder();

  const handleStopRecording = () => {
    stopRecording();
  };

  React.useEffect(() => {
    if (audioBlob) {
      onRecordingComplete(audioBlob);
    }
  }, [audioBlob, onRecordingComplete]);

  return (
    <Box>
      <Button onClick={isRecording ? handleStopRecording : startRecording}>
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </Button>
      <WaveformDisplay isRecording={isRecording} getAudioData={getAudioData} />
    </Box>
  );
};

export default AudioRecorder;