import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Progress,
} from '@chakra-ui/react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

const ExportModal = ({ isOpen, onClose, audioBlob, selection }) => {
  const [exportFormat, setExportFormat] = useState('wav');
  const [ffmpeg, setFfmpeg] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const loadFFmpeg = async () => {
      const ffmpegInstance = new FFmpeg();
      ffmpegInstance.on('progress', ({ progress }) => {
        setProgress(Math.round(progress * 100));
      });
      await ffmpegInstance.load();
      setFfmpeg(ffmpegInstance);
    };

    loadFFmpeg();
  }, []);

  const handleExport = async () => {
    if (!audioBlob || !ffmpeg) return;

    const inputFormat = audioBlob.type.split('/')[1];
    const inputFileName = `input.${inputFormat}`;
    const outputFileName = `output.${exportFormat}`;

    try {
      await ffmpeg.writeFile(inputFileName, await fetchFile(audioBlob));

      let ffmpegCommand = [];
      if (selection) {
        ffmpegCommand = [
          '-i', inputFileName,
          '-ss', selection.start.toString(),
          '-to', selection.end.toString(),
          '-c:a', exportFormat === 'wav' ? 'pcm_s16le' : 'flac',
          outputFileName
        ];
      } else {
        ffmpegCommand = [
          '-i', inputFileName,
          '-c:a', exportFormat === 'wav' ? 'pcm_s16le' : 'flac',
          outputFileName
        ];
      }

      await ffmpeg.exec(ffmpegCommand);

      const data = await ffmpeg.readFile(outputFileName);
      const url = URL.createObjectURL(new Blob([data.buffer], { type: `audio/${exportFormat}` }));
      const a = document.createElement('a');
      a.href = url;
      a.download = outputFileName;
      a.click();
      URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Export failed:', error);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Audio</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select value={exportFormat} onChange={(e) => setExportFormat(e.target.value)} mb={4}>
            <option value="wav">WAV</option>
            <option value="flac">FLAC</option>
          </Select>
          {progress > 0 && <Progress value={progress} mb={4} />}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleExport} isDisabled={!ffmpeg}>
            Export
          </Button>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExportModal;