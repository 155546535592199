export const createWaveformData = async (audioBlob, width) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    
    const channelData = audioBuffer.getChannelData(0);
    const samples = width;
    const blockSize = Math.floor(channelData.length / samples);
    const waveformData = [];
  
    for (let i = 0; i < samples; i++) {
      const start = i * blockSize;
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum += Math.abs(channelData[start + j]);
      }
      waveformData.push(sum / blockSize);
    }
  
    const multiplier = Math.pow(Math.max(...waveformData), -1);
    return waveformData.map(n => n * multiplier);
  };
  
  export const audioBufferToBlob = (audioBuffer) => {
    const channelData = audioBuffer.getChannelData(0);
    const wavFile = createWavFile(channelData, audioBuffer.sampleRate);
    return new Blob([wavFile], { type: 'audio/wav' });
  };
  
  function createWavFile(samples, sampleRate) {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);
  
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + samples.length * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, samples.length * 2, true);
  
    const volume = 1;
    let index = 44;
    for (let i = 0; i < samples.length; i++) {
      view.setInt16(index, samples[i] * (0x7FFF * volume), true);
      index += 2;
    }
  
    return buffer;
  }
  
  function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }