import React, { useState, useEffect } from 'react';
import { Box, Text, Select } from '@chakra-ui/react';
import WaveformDisplay from './WaveformDisplay';

const AudioTimeline = ({ duration, onSelectionChange, audioBlob, onTimelineClick }) => {
  const [selection, setSelection] = useState(null);
  const [selectionDuration, setSelectionDuration] = useState(1);

  useEffect(() => {
    if (!duration || isNaN(duration)) {
      onSelectionChange(null);
    }
  }, [duration, onSelectionChange]);

  const handleClick = (e) => {
    if (!duration || isNaN(duration)) return;

    const clickPosition = e.nativeEvent.offsetX / e.target.offsetWidth;
    const time = clickPosition * duration;
    
    setSelection(time);
    onSelectionChange({ start: time, end: Math.min(time + selectionDuration, duration) });
    onTimelineClick();
  };

  const handleSelectionDurationChange = (e) => {
    setSelectionDuration(Number(e.target.value));
    if (selection !== null) {
      onSelectionChange({ start: selection, end: Math.min(selection + Number(e.target.value), duration) });
    }
  };

  const formatTime = (timeInSeconds) => {
    if (isNaN(timeInSeconds) || !isFinite(timeInSeconds)) return '0:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Box>
      <Select value={selectionDuration} onChange={handleSelectionDurationChange} mb={2}>
        <option value={1}>1 second</option>
        <option value={2}>2 seconds</option>
        <option value={5}>5 seconds</option>
      </Select>
      <Box
        height="50px"
        bg="gray.200"
        onClick={handleClick}
        position="relative"
        overflow="hidden"
      >
        {audioBlob && <WaveformDisplay audioBlob={audioBlob} width="100%" height="50px" />}
        {selection !== null && !isNaN(duration) && isFinite(duration) && (
          <Box
            position="absolute"
            left={`${(selection / duration) * 100}%`}
            width={`${(selectionDuration / duration) * 100}%`}
            height="100%"
            bg="blue.500"
            opacity={0.5}
          />
        )}
        <Text position="absolute" top="0" left="0" zIndex="1">
          {formatTime(0)}
        </Text>
        <Text position="absolute" top="0" right="0" zIndex="1">
          {formatTime(duration)}
        </Text>
      </Box>
    </Box>
  );
};

export default AudioTimeline;